@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;900&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap'); */

:root {
    --tri-font-family: 'Inter', sans-serif;
    --tri-gradient: linear-gradient(180deg, rgba(233, 91, 43, 1) 0%, rgba(245, 163, 3, 1) 100%);
    --tri-primary: #00274E;
    --tri-header: #F9F9F9;
    --tri-background: #ffffff;
    --tri-body: #F9F9F9;
    --tri-content-header: #F9F9F9;
    --tri-menu-active: #F1F1F1;
    --tri-form-control: #ffffff;
    --tri-scroll-gray: rgb(196, 196, 196);
}


/*
******************************
    Custom Scrollbar
******************************
*/

::-webkit-scrollbar {
    width: 0.625rem;
}

::-webkit-scrollbar-track {
    background: var(--tri-background);
}

::-webkit-scrollbar-thumb {
    background: var(--tri-scroll-gray);
}


/****************************** ******************************/

body {
    /* font-family: 'Roboto', sans-serif; */
    font-family: var(--tri-font-family);
    font-size: 14px;
    color: var(--tri-primary);
    background-color: var(--tri-body);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    color: var(--tri-primary);
}

.fw-100 {
    font-weight: 100;
}

.fw-200 {
    font-weight: 200;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.font-10 {
    font-size: 0.625rem;
}

.font-10 {
    font-size: 0.625rem;
}

.font-11 {
    font-size: 0.688rem;
}

.font-12 {
    font-size: 0.75rem;
}

.font-13 {
    font-size: 0.813rem;
}

.font-14 {
    font-size: 0.875rem;
}

.font-15 {
    font-size: 0.938rem;
}

.font-16 {
    font-size: 1rem;
}

.font-17 {
    font-size: 1.063rem;
}

.font-18 {
    font-size: 1.125rem;
}

.font-19 {
    font-size: 1.188rem;
}

.font-20 {
    font-size: 1.25rem;
}

.font-21 {
    font-size: 1.313rem;
}

.font-22 {
    font-size: 1.375rem;
}

.font-23 {
    font-size: 1.438rem;
}

.font-24 {
    font-size: 1.5rem;
}

.font-25 {
    font-size: 1.563rem;
}


/* Width */

.w-30 {
    width: 30%;
}

.w-35 {
    width: 35%;
}

.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-55 {
    width: 55%;
}

.pointer {
    cursor: pointer;
}

:focus {
    outline: none !important;
}

[class^="bi-"]::before,
[class*=" bi-"]::before {
    vertical-align: -0.220em !important;
}

.tri-text-primary {
    color: var(--tri-primary) !important;
}

.user-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    height: 38px;
    width: 38px;
    border-radius: 8px;
    background-color: rgba(177, 177, 177, 0.2);
}

.user-icon img {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    height: 38px;
    width: 38px;
    border-radius: 8px;
    background-color: rgba(177, 177, 177, 0.2);
}

.user-info-circle {
    background-color: #c7e6fb;
    border: 1px solid #00274e;
    border-radius: 50px;
}

.form-group {
    margin-bottom: 1rem;
}

.text-muted {
    color: #8a96a0 !important;
}


/* -----------------------------------------------------DESIGN CSS ---------------------------------------- */

.btn-tri-primary {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    padding: .35rem .5rem;
    background: var(--tri-gradient);
    color: #ffffff;
    font-weight: 500;
    font-size: 0.875rem;
    border-radius: 5px;
    border: none;
}

.btn-tri-primary.disabled,
.btn-tri-primary:disabled {
    opacity: 0.5;
}


/* /////////////////////Card Design///////////////// */

.tri-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
    border: none;
    background-color: var(--tri-background);
    margin-bottom: 1.5rem;
}

.tri-card-header {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding: .5rem 1rem;
}

.tri-card-header-bg {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    padding: .5rem 1rem;
    background-color: var(--tri-content-header);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.tri-card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}


/* //////////////////////Nav Tabs Design/////////////// */


/* Button tab design */

.navtab-btn {
    padding: .45rem .8rem;
    border: none;
    background-color: transparent;
    border-radius: 5px;
    color: var(--tri-primary);
}

.navtab-btn.active {
    background: var(--tri-gradient);
    color: #ffffff;
    font-weight: 500;
}


/* Line Tab design */

.bottomline-tab.nav-tabs .nav-link {
    border: 2px solid transparent;
    border-radius: 0;
    margin-bottom: 0;
    color: var(--tri-primary);
}

.bottomline-tab.nav-tabs .nav-item.show .nav-link,
.bottomline-tab.nav-tabs .nav-link.active {
    font-weight: 500;
    background-color: transparent;
    border-color: transparent transparent #00274e;
}


/* /////////////////////Kendo Search ///////////////// */

.search-box {
    border-radius: 5px !important;
}

.search-input-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    color: var(--tri-primary);
    font-size: 0.875rem;
    cursor: pointer;
}


/* /////////////////////Kendo Grid ///////////////// */

.tri-grid.k-grid tr.k-alt {
    background-color: #ffffff !important;
}

.tri-grid.k-grid th {
    padding: 5px 12px;
    color: var(--tri-primary);
    font-weight: 500;
}


/* -----------------------DARK THEME-------------------- */

.theme-dark .bg-light {
    background-color: #2c3034!important;
}

.theme-dark .k-grid td {
    background-color: var(--tri-background);
    color: var(--tri-primary);
}

.theme-dark .tri-grid.k-grid {
    border-color: var(--tri-content-header);
}

.theme-dark .k-grid .k-grid-filter,
.k-grid .k-header-column-menu,
.k-grid .k-hierarchy-cell .k-icon {
    color: var(--tri-primary) !important;
}

.theme-dark .k-pager-wrap {
    background-color: var(--tri-background);
    color: var(--tri-primary);
}

.theme-dark .form-control {
    border: 1px solid transparent;
}

.theme-dark .k-textbox {
    background-color: #212529;
    color: var(--tri-primary);
}

.theme-dark svg rect {
    fill: var(--tri-background);
}

.theme-dark .bottomline-tab.nav-tabs .nav-item.show .nav-link,
.theme-dark .bottomline-tab.nav-tabs .nav-link.active {
    border-color: transparent transparent #FD7E14;
}

.theme-dark .list-group-item {
    background-color: var(--tri-background);
    color: var(--tri-primary);
}

.theme-dark .bottomline-tab.nav-tabs {
    border-bottom: 1px solid #545454;
}

.theme-dark .k-grid-header {
    border-color: var(--tri-content-header);
    background-color: var(--tri-content-header);
}

.theme-dark .k-grid tbody tr:hover {
    color: #C4C4C4;
}

.theme-dark .sidebar-link {
    color: #C4C4C4 !important;
}

.theme-dark .sidebar-link:hover {
    background-color: #32373e !important;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
}

.theme-dark .c-btn {
    background-color: #212529;
    color: var(--tri-primary);
}

.theme-dark svg {
    fill: var(--tri-primary);
}

.theme-dark .k-dropdown .k-dropdown-wrap,
.k-dropdowntree .k-dropdown-wrap {
    background-color: #595b5e;
    color: var(--tri-primary);
}

.theme-dark .k-treeview {
    color: var(--tri-primary);
}

.theme-dark .k-grid-content {
    background-color: var(--tri-background);
}


/* Dropdown CSS */

.c-btn {
    min-height: 31px !important;
    padding: 1px 5px !important;
    border-radius: 2px !important;
    border-color: rgba(0, 0, 0, 0.15) !important;
}

.selected-list .c-list .c-token {
    margin-top: 0 !important;
}

.pure-checkbox label {
    font-size: 14px !important;
}

.dropdown-list ul li:last-child {
    padding-bottom: 51px !important;
}

.pure-checkbox>input[type=checkbox]+label:before {
    content: '';
    box-sizing: content-box;
    position: absolute;
    top: 63% !important;
    left: 0;
    width: 10px !important;
    height: 10px !important;
    margin-top: -9px;
    text-align: center;
    transition: .4s;
    border-radius: 3px;
}

.pure-checkbox>input[type=checkbox]+label:after {
    content: '';
    box-sizing: content-box;
    position: absolute;
    /* -webkit-transform: scale(0); */
    /* transform: scale(0); */
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    transition: transform .2s ease-out, -webkit-transform .2s ease-out;
    background-color: transparent;
    top: 41% !important;
    left: 3px;
    width: 5px !important;
    height: 2px !important;
    margin-top: -1px !important;
    border-style: solid;
    border-width: 0 0 2px 2px;
    -o-border-image: none;
    border-image: none;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
}

.cuppa-dropdown .dropdown-list ul li {
    padding: 5px 10px;
}

.pure-checkbox input[type=checkbox]+label {
    padding-left: 1.5em !important;
}


/* Responsive styles start */


/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    .responsive-list ul {
        flex-wrap: nowrap;
    }
    .responsive-list ul li {
        min-width: auto;
    }
    .responsive-list,
    .k-pager-wrap {
        overflow: auto;
        overflow-x: auto;
    }
    .responsive-list::-webkit-scrollbar,
    .k-pager-wrap::-webkit-scrollbar {
        display: none;
        overflow: -moz-scrollbars-none;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
    .responsive-list ul {
        flex-wrap: wrap;
    }
    .responsive-list ul li {
        min-width: auto;
    }
    .responsive-list,
    .k-pager-wrap {
        overflow: auto;
        overflow-x: auto;
    }
    .responsive-list::-webkit-scrollbar,
    .k-pager-wrap::-webkit-scrollbar {
        display: none;
        overflow: -moz-scrollbars-none;
    }
}


/* End */


/* Kendo Grid Icon */

.audit-grid .k-i-plus::before,
.k-plus::before,
.k-i-add::before {
    content: "\e005 " !important;
}

.audit-grid .k-i-minus::before,
.k-minus::before {
    content: "\e006 " !important;
}

.audit-grid.k-grid {
    font-size: 13px !important;
}

.audit-grid.k-grid tbody tr {
    line-height: 15px !important;
}

.audit-grid.k-grid tbody td {
    padding: 2px 8px;
}

.k-grid .k-hierarchy-cell>.k-icon {
    padding: 0 !important;
}


/* .audit-grid .k-grid-header .k-header>.k-link{
      padding: 3px 5px !important;
  } */


/* End */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

//.wrapper{margin-top: 61px;}
.code{display: block; overflow-x: auto; padding: 10px; background: #f0f5ff;}
.inner_wrapper{margin: 30px 0 60px; display: inline-block; }
